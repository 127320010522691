import { Box, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'

function NeopolisLogo() {
    const theme = useTheme();

    const [color, setColor] = useState("black");
    const [textColor, setTextColor] = useState(theme.palette.primary["900"]);
    const [offset, setOffset] = useState(0);

    function randomizeLogo(){
        const chance = Math.floor(Math.random()*100);
        if (chance>5){
            setColor(theme.palette.primary["100"]);
            setTextColor(theme.palette.primary["500"]);
        }else{
            setColor("black");
            setTextColor(theme.palette.primary["900"]);
        }
        setTimeout(randomizeLogo, Math.floor(Math.random()*2500)+500);
    }

    function flickerOffset(){
        const offset = 7-(Math.floor(Math.random()*14));
        setOffset(offset);
        setTimeout(flickerOffset, Math.floor(Math.random()*100)+100);
    }

    useEffect(()=>{
        randomizeLogo();
        flickerOffset();
    }, []);

  return (
    <Box sx={{
        transition: "0.2s",
        margin: "auto",
        width: "fit-content",
        marginBottom: 2,
        fontFamily: "neopolis",
        color: textColor,
        fontSize: 30,
        opacity: `${86+(offset*2)}%`,
        textShadow: `0 0 ${10+offset}px ${color}, 0 0 ${20+offset}px ${color}, 0 0 ${40+offset}px ${color}, 0 0 ${80+offset}px ${color}, -1px -1px 0 ${color}, 1px -1px 0 ${color}, -1px 1px 0 ${color}, 1px 1px 0 ${color};`,
    }}>
        Neopolis
    </Box>
  )
}

export default NeopolisLogo