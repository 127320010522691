import { Button, useTheme } from '@mui/material'
import React from 'react'
import ButtonClick from '../sounds/click.ogg';

function NeonButton({sound=false, onClick=()=>{}, children, color="primary", active=false, small=false, large=false}) {
  const SOUND = new Audio(ButtonClick);
  const theme = useTheme();
    return (
    <Button 
        className={active?"active":""}
        onClick={(...args)=>{
            onClick(...args);
            if (sound){
                SOUND.play();
            }
        }}
        variant='contained'
        color={color}
        sx={{
            width: "fit-content",
            minWidth: "fit-content",
            color: "black",
            boxShadow: `0 0 10px ${theme.palette[color]["300"]}, 0 0 20px ${theme.palette[color]["300"]}, 0 0 40px ${theme.palette[color]["300"]}`,
            transition: `0.2s`,
            '&:hover, &.active': {
                boxShadow: `0 0 20px ${theme.palette[color]["50"]}, 0 0 40px ${theme.palette[color]["50"]}, 0 0 60px ${theme.palette[color]["50"]}`,
                backgroundColor: theme.palette[color]["50"],
                color: theme.palette[color]["600"]
            }
        }}
        size={large?"large":small?"small":"medium"}
    >
        {children}
    </Button>
  )
}

export default NeonButton