import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';

const THEME = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#ff2a6d",  // Gelb
      "500": "#ff3f7c",
      "400": "#ff558a",
      "300": "#ff6a99",
      "200": "#ff7fa7",
      "100": "#ff95b6",
      "50": "#ffaac5",
      "600": "#e62662",
      "700": "#cc2257",
      "800": "#b31d4c",
      "900": "#991941",
      contrastText: "#19040b"
    },
    secondary: {
      main: "#7700a6",
      "500": "#851aaf",
      "400": "#9233b8",
      "300": "#a04dc1",
      "200": "#ad66ca",
      "100": "#bb80d3",
      "50": "#c999db",
      "600": "#6b0095",
      "700": "#5f0085",
      "800": "#530074",
      "900": "#470064",
      contrastText: "#180021"
    },
    info: {
      main: "#0016ee",
      "500": "#1a2df0",
      "400": "#3345f1",
      "300": "#4d5cf3",
      "200": "#6673f5",
      "100": "#808bf7",
      "50": "#99a2f8",
      "600": "#0014d6",
      "700": "#0012be",
      "800": "#000fa7",
      "900": "#000d8f",
      contrastText: "#000b77"
    },
    success: {
      main: "#1afe49",
      "500": "#31fe5b",
      "400": "#48fe6d",
      "300": "#5ffe80",
      "200": "#76fe92",
      "100": "#8dffa4",
      "50": "#a3ffb6",
      "600": "#17e542",
      "700": "#15cb3a",
      "800": "#12b233",
      "900": "#10982c",
      contrastText: "#031907"
    },
    warning: {
      main: "#ffa500",
      "500": "#ffae1a",
      "400": "#ffb733",
      "300": "#ffc04d",
      "200": "#ffc966",
      "100": "#ffd280",
      "50": "#ffdb99",
      "600": "#e69500",
      "700": "#cc8400",
      "800": "#b37300",
      "900": "#996300",
      contrastText: "#191000"
    },
    error: {
      main: "#ff073a",
      "500": "#ff204e",
      "400": "#ff3961",
      "300": "#ff5175",
      "200": "#ff6a89",
      "100": "#ff839d",
      "50": "#ff9cb0",
      "600": "#e60634",
      "700": "#cc062e",
      "800": "#b30529",
      "900": "#990423",
      contrastText: "#190106"
    },
    background: {
      default: "#02060e",
      paper: "#2A3056"
    },
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={THEME}>
      <CssBaseline/>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
