import { Box, Paper, useTheme } from "@mui/material";
import RPButton from "./components/RP_Button";
import NeonButton from "./components/NeonButton";
import NeopolisLogo from "./components/Neopolis_Logo";


function App() {
  const theme = useTheme();
  return (
    <div className="App" style={{minWidth: "fit-content", padding: "30px"}}>
      <NeopolisLogo/>
      <header className="App-header">
        <Box sx={{
          width: "fit-content",
          m: "auto",
          fontWeight: "bolder",
          color: theme.palette.primary["600"],
          fontSize: 30,
          transition: `0.3s`,
        }}>
          Normal
        </Box>
        <div style={{paddingTop: 2, display: "flex", justifyContent: "center", gap: "100px"}}>
          <NeonButton sound color="primary" >
            Primary
          </NeonButton>
          <NeonButton sound color="secondary">
            Secondary
          </NeonButton>
          <NeonButton sound color="info">
            Info
          </NeonButton>
          <NeonButton sound color="success">
            Success
          </NeonButton>
          <NeonButton sound color="warning">
            Warning
          </NeonButton>
          <NeonButton sound color="error">
            Error
          </NeonButton>
        </div>
        <Box sx={{
          width: "fit-content",
          m: "auto",
          fontWeight: "bolder",
          color: theme.palette.primary["600"],
          fontSize: 30,
          marginTop: 5,
          transition: `0.3s`,
        }}>
          Small
        </Box>
        <div style={{paddingTop: 2, display: "flex", justifyContent: "center", gap: "100px"}}>
          <NeonButton sound color="primary" small>
            Primary
          </NeonButton>
          <NeonButton sound color="secondary" small>
            Secondary
          </NeonButton>
          <NeonButton sound color="info" small>
            Info
          </NeonButton>
          <NeonButton sound color="success" small>
            Success
          </NeonButton>
          <NeonButton sound color="warning" small>
            Warning
          </NeonButton>
          <NeonButton sound color="error" small>
            Error
          </NeonButton>
        </div>
        <Box sx={{
          width: "fit-content",
          m: "auto",
          fontWeight: "bolder",
          color: theme.palette.primary["600"],
          fontSize: 30,
          marginTop: 5,
          transition: `0.3s`,
        }}>
          Large
        </Box>
        <div style={{paddingTop: 2, display: "flex", justifyContent: "center", gap: "100px"}}>
          <NeonButton sound color="primary" large>
            Primary
          </NeonButton>
          <NeonButton sound color="secondary" large>
            Secondary
          </NeonButton>
          <NeonButton sound color="info" large>
            Info
          </NeonButton>
          <NeonButton sound color="success" large>
            Success
          </NeonButton>
          <NeonButton sound color="warning" large>
            Warning
          </NeonButton>
          <NeonButton sound color="error" large>
            Error
          </NeonButton>
        </div>
      </header>
    </div>
  );
}

export default App;
